import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { IntercomProvider } from "react-use-intercom";
import { StrictMode } from "react";

ReactDOM.render(
    <StrictMode>
        <IntercomProvider appId="szhbubfv">
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </IntercomProvider>
    </StrictMode>,
    document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
